// @generated
// This file was automatically generated and should not be edited.

import gql from 'graphql-tag';

const fragments = gql`
  fragment AboutUsSectionFragment on SanityAboutUsSection {
    _key
    _type
    subtitle
    title
    content: _rawContent(resolveReferences: { maxDepth: 10 })
    primaryButton {
      buttonLabel
      link {
        ...LinkFragment
      }
    }
    secondaryButton {
      buttonLabel
      link {
        ...LinkFragment
      }
    }
    images {
      _key
      image {
        ...ImageFragment
      }
      mobileImage {
        ...ImageFragment
      }
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
  fragment ActivityBookingSectionFragment on SanityActivityBookingSection {
    _key
    _type
    title
    subtitle
    description
    emailSubject
    price
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
  fragment BookNowSectionFragment on SanityBookNowSection {
    _key
    _type
    title
    subtitle
    description
    tabs {
      _key
      _type
      heading
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      buttonLink {
        buttonLabel
        link {
          ...LinkFragment
        }
      }
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
  fragment HeroBaseSectionFragment on SanityHeroBaseSection {
    _key
    _type
    title
    isPageTitle
    subtitle
    content: _rawContent(resolveReferences: { maxDepth: 10 })
    scrollButtonLabel
    hasTextBgOverlay
    images {
      _key
      image {
        ...ImageFragment
      }
      mobileImage {
        ...ImageFragment
      }
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
  fragment HeroWithContactFormSectionFragment on SanityHeroWithContactFormSection {
    _key
    _type
    title
    subtitle
    content: _rawContent(resolveReferences: { maxDepth: 10 })
    scrollButtonLabel
    images {
      _key
      image {
        ...ImageFragment
      }
      mobileImage {
        ...ImageFragment
      }
    }
    contact {
      _key
      title
      desc: _rawDesc(resolveReferences: { maxDepth: 10 })
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
  fragment HeroWithPostsSectionFragment on SanityHeroWithPostsSection {
    _key
    _type
    scrollButtonLabel
    teaserButtonLabel
    posts {
      key: id
      title
      subtitle
      publishDate(formatString: "MMMM d, yyyy")
      firstName
      lastName
      images {
        _key
        image {
          ...ImageFragment
        }
        mobileImage {
          ...ImageFragment
        }
      }
      slug {
        current
      }
      hasTextBgOverlay
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
  fragment HeroWithTabsSectionFragment on SanityHeroWithTabsSection {
    _key
    _type
    title
    subtitle
    content: _rawContent(resolveReferences: { maxDepth: 10 })
    scrollButtonLabel
    images {
      _key
      image {
        ...ImageFragment
      }
      mobileImage {
        ...ImageFragment
      }
    }
    tabs {
      _key
      heading
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      buttonLink {
        buttonLabel
        link {
          ...LinkFragment
        }
      }
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
  fragment HotelsSectionFragment on SanityHotelsSection {
    _key
    _type
    cards {
      _key
      title
      price {
        ...PriceFragment
      }
      description
      icon {
        ...ImageFragment
      }
      to {
        ...LinkFragment
      }
      isDark
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
  fragment HotelsTabsSectionFragment on SanityHotelsTabsSection {
    _key
    _type
    isReversed
    hotels {
      _key
      heading: title
      title: heading
      images {
        _key
        image {
          ...ImageFragment
        }
        mobileImage {
          ...ImageFragment
        }
      }
      buttonLink {
        buttonLabel
        link {
          ...LinkFragment
        }
      }
      content: _rawDescription(resolveReferences: { maxDepth: 10 })
      annotation: _rawAnnotation(resolveReferences: { maxDepth: 10 })
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
  fragment HotelWithFeaturesListSectionFragment on SanityHotelWithFeaturesListSection {
    _key
    _type
    subtitle
    title
    content: _rawContent(resolveReferences: { maxDepth: 10 })
    mainFeatures {
      title
      icon
    }
    additionalFeatures {
      title
      icon
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
  fragment ImagesGallerySectionFragment on SanityImagesGallerySection {
    _key
    _type
    items {
      _key
      title
      subtitle
      image {
        ...ImageFragment
      }
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
  fragment LocationSectionFragment on SanityLocationSection {
    _key
    _type
    subtitle
    title
    address
    addressSubtitle
    buttonLink {
      buttonLabel
      link {
        ...LinkFragment
      }
    }
    items {
      _key
      iconMode
      title
      distance
    }
    coordinates {
      latitude: lat
      longitude: lng
      alt
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
  fragment PostTeasersSectionFragment on SanityPostTeasersSection {
    _key
    _type
    title
    link {
      buttonLabel
      link {
        ...LinkFragment
      }
    }
    withAllPosts
    articles {
      _key
      id
      i18n_lang
      title
      slug {
        current
      }
      images {
        _key
        mobileImage {
          ...ImageFragment
        }
        image {
          ...ImageFragment
        }
      }
      firstName
      lastName
      publishDate
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
  fragment ReviewsCarouselSectionFragment on SanityReviewsCarouselSection {
    _key
    _type
    subtitle
    title
    description
    buttonLabel
    reviews {
      _key
      review {
        _key
        comment
        date
        rate
      }
      author {
        _key
        firstName
        lastName
        avatar {
          ...ImageFragment
        }
      }
    }
    serviceRatings {
      _key
      name
      rate
      maxRate
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
  fragment SocialIconsSectionFragment on SanitySocialIconsSection {
    _key
    _type
    text
    iconsSet
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
  fragment TeamSectionFragment on SanityTeamSection {
    _key
    _type
    title
    items {
      _key
      title
      subtitle
      description: _rawDescription(resolveReferences: { maxDepth: 10 })
      link {
        ...LinkFragment
      }
      image {
        ...ImageFragment
      }
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
  fragment TextListSectionFragment on SanityTextListSection {
    _key
    _type
    items {
      _key
      title
      desc: _rawDesc(resolveReferences: { maxDepth: 10 })
    }
    innerMode
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
  fragment TextSectionFragment on SanityTextSection {
    _key
    _type
    innerMode
    content: _rawContent(resolveReferences: { maxDepth: 10 })
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
  fragment TilesSectionFragment on SanityTilesSection {
    _key
    _type
    title
    tiles {
      _key
      label
      buttonLink {
        buttonLabel
        link {
          ...LinkFragment
        }
      }
      image {
        ...ImageFragment
      }
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
  fragment TitleFaqSectionFragment on SanityTitleFaqSection {
    _key
    _type
    title
    faqsList {
      _key
      question
      answer: _rawAnswer(resolveReferences: { maxDepth: 10 })
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
  fragment TransportOptionsSectionFragment on SanityTransportOptionsSection {
    _key
    _type
    title
    items {
      _key
      title
      link {
        ...LinkFragment
      }
      image {
        ...ImageFragment
      }
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
  fragment TransportScheduleSectionFragment on SanityTransportScheduleSection {
    _key
    _type
    items: transportGroup {
      _key
      title
      subtitle
      items: transportDayItem {
        _key
        title
        items: transportItem {
          _key
          iconMode
          desc
        }
      }
    }
    innerMode
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
  fragment VideoBoxSectionFragment on SanityVideoBoxSection {
    _key
    _type
    title
    content: _rawContent(resolveReferences: { maxDepth: 10 })
    backgroundImage {
      image {
        ...ImageFragment
      }
      mobileImage {
        ...ImageFragment
      }
    }
    video {
      title
      url
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
  fragment WideBannerSectionFragment on SanityWideBannerSection {
    _key
    _type
    buttonLabel
    slides {
      _key
      title
      desc
      image {
        ...ImageFragment
      }
      mobileImage {
        ...ImageFragment
      }
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
  fragment PriceFragment on SanityPrice {
    amount
    currency
    unit
  }
  fragment ImageFragment on SanityImageWithAlt {
    alt
    asset {
      gatsbyImageData(placeholder: BLURRED)
    }
  }
  fragment LinkFragment on SanityLink {
    _key
    type
    resource {
      ... on SanityPage {
        slug {
          current
        }
      }
      ... on SanityPost {
        slug {
          current
        }
      }
      ... on SanityBlogListingPage {
        slug {
          current
        }
      }
    }
    url
    media {
      asset {
        url
      }
    }
  }
  fragment FooterFragment on SanityFooter {
    logoUrl {
      ...LinkFragment
    }
    copyrights
    menu {
      _key
      buttonLabel
      link {
        ...LinkFragment
      }
    }
    extractedButton {
      buttonLabel
      link {
        ...LinkFragment
      }
    }
    terms {
      buttonLabel
      link {
        ...LinkFragment
      }
    }
    location {
      title
      desc: _rawDesc(resolveReferences: { maxDepth: 10 })
    }
    contact {
      _key
      title
      desc: _rawDesc(resolveReferences: { maxDepth: 10 })
    }
    socialsLabel
    socials {
      _key
      iconMode
      link {
        ...LinkFragment
      }
    }
  }
  fragment HeaderFragment on SanityHeader {
    logoUrl {
      ...LinkFragment
    }
    menu {
      _key
      buttonLabel
      link {
        ...LinkFragment
      }
    }
    extractedButton {
      buttonLabel
      link {
        ...LinkFragment
      }
    }
  }
  fragment CookieInfoFragment on SanityCookieInfo {
    title
    desc: _rawDesc(resolveReferences: { maxDepth: 10 })
    label
  }
  fragment PromoBannerFragment on SanityPromoBanner {
    title
    desc: _rawDesc(resolveReferences: { maxDepth: 10 })
    isActive
    image {
      ...ImageFragment
    }
    button {
      buttonLabel
      link {
        ...LinkFragment
      }
    }
  }
`;

export default fragments;
